<template>
    <div>
        <NavOnlineAdmission></NavOnlineAdmission>
        <b-container>
            <b-row class="mt-3 justify-content-center">
                <SideNavOnlineAdmission :institute="institute" :formId="formId" :temporaryId="temporaryId">
                </SideNavOnlineAdmission>

                <b-col cols="12" md="9" class="">
                    <b-card>
                        <b-card-body>

                            <b-row>
                                <div class="col-md-4">
                                    <b-form-group label-for="group">
                                        <template v-slot="label">
                                            <label>Groups <span class="text-danger">*</span></label>
                                            <b-form-select v-model="formData.group" :options="groups"></b-form-select>
                                            <b-form-invalid-feedback :state="groupState">
                                                <span v-if="errors.group">{{ errors.group[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="class_id">
                                        <template v-slot="label">
                                            <label>Class <span class="text-danger">*</span></label>
                                            <select class="form-control" id="exampleFormControlSelect1"
                                                v-model="formData.class_id">
                                                <option value="null">Please Select Class</option>
                                                <option v-for="item in classes" :value="item.id">{{ item.name }}</option>
                                            </select>
                                            <b-form-invalid-feedback :state="classIdState">
                                                <span v-if="errors.class_id">{{ errors.class_id[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>

                                </div>

                                <div class="col-md-4">
                                    <b-form-group label="Email:" label-for="email">
                                        <b-form-input type="email" id="email" v-model="formData.email"
                                            placeholder="Enter your email" />
                                        <b-form-invalid-feedback :state="emailState">
                                            <span v-if="errors.email">{{ errors.email[0] }}</span>
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="father_name">
                                        <template v-slot="label">
                                            <label>Father Name <span class="text-danger">*</span></label>
                                            <b-form-input id="father_name" v-model="formData.father_name"
                                                placeholder="Enter your father name" />
                                            <b-form-invalid-feedback :state="fatherNameState">
                                                <span v-if="errors.father_name">{{ errors.father_name[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="father_nid">
                                        <template v-slot="label">
                                            <label>Father NID <span class="text-danger">*</span></label>
                                            <b-form-input type="number" id="father_nid" v-model="formData.father_nid"
                                                placeholder="Enter your father nid" />
                                            <b-form-invalid-feedback :state="fatherNidState">
                                                <span v-if="errors.father_nid">{{ errors.father_nid[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="father_mobile_no">
                                        <template v-slot="label">
                                            <label>Father Mobile Number <span class="text-danger">*</span></label>
                                            <b-form-input type="number" id="father_mobile_no"
                                                v-model="formData.father_mobile_no"
                                                placeholder="Enter your father mobile number" />
                                            <b-form-invalid-feedback :state="fatherMobileState">
                                                <span v-if="errors.father_mobile_no">{{ errors.father_mobile_no[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="mother_name">
                                        <template v-slot="label">
                                            <label>Mother Name <span class="text-danger">*</span></label>
                                            <b-form-input id="mother_name" v-model="formData.mother_name"
                                                placeholder="Enter your mother name" />
                                            <b-form-invalid-feedback :state="motherNameState">
                                                <span v-if="errors.mother_name">{{ errors.mother_name[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="mother_nid">
                                        <template v-slot="label">
                                            <label>Mother NID <span class="text-danger">*</span></label>
                                            <b-form-input type="number" id="mother_nid" v-model="formData.mother_nid"
                                                placeholder="Enter your mother nid" />
                                            <b-form-invalid-feedback :state="motherNidState">
                                                <span v-if="errors.mother_nid">{{ errors.mother_nid[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="mother_mobile_no">
                                        <template v-slot="label">
                                            <label>Mother Mobile Number <span class="text-danger">*</span></label>
                                            <b-form-input type="number" id="mother_mobile_no"
                                                v-model="formData.mother_mobile_no"
                                                placeholder="Enter your mother mobile number" />
                                            <b-form-invalid-feedback :state="motherMobileState">
                                                <span v-if="errors.mother_mobile_no">{{ errors.mother_mobile_no[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="guardian_name">
                                        <template v-slot="label">
                                            <label>Guardian Name <span class="text-danger">*</span></label>
                                            <b-form-input id="guardian_name" v-model="formData.guardian_name"
                                                placeholder="Enter your guardian name" />
                                            <b-form-invalid-feedback :state="guardianNameState">
                                                <span v-if="errors.guardian_name">{{ errors.guardian_name[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="guardian_nid">
                                        <template v-slot="label">
                                            <label>Guardian NID <span class="text-danger">*</span></label>
                                            <b-form-input type="number" id="guardian_nid" v-model="formData.guardian_nid"
                                                placeholder="Enter your guardian NID" />
                                            <b-form-invalid-feedback :state="guardianNidState">
                                                <span v-if="errors.guardian_nid">{{ errors.guardian_nid[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="guardian_mobile_no">
                                        <template v-slot="label">
                                            <label>Guardian Mobile Number <span class="text-danger">*</span></label>
                                            <b-form-input type="number" id="guardian_mobile_no"
                                                v-model="formData.guardian_mobile_no"
                                                placeholder="Enter your guardian mobile number" />
                                            <b-form-invalid-feedback :state="guardianMobileState">
                                                <span v-if="errors.guardian_mobile_no">{{ errors.guardian_mobile_no[0]
                                                }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>


                                <div class="col-md-4">
                                    <b-form-group label-for="religion">
                                        <template v-slot="label">
                                            <label>Religion <span class="text-danger">*</span></label>
                                            <b-form-select v-model="formData.religion" :options="religions"></b-form-select>
                                            <b-form-invalid-feedback :state="religionState">
                                                <span v-if="errors.religion">{{ errors.religion[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="nationality">
                                        <template v-slot="label">
                                            <label>Nationality <span class="text-danger">*</span></label>
                                            <b-form-select v-model="formData.nationality"
                                                :options="nationalities"></b-form-select>
                                            <b-form-invalid-feedback :state="nationalityState">
                                                <span v-if="errors.nationality">{{ errors.nationality[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="communication_mobile_no">
                                        <template v-slot="label">
                                            <label>Communication Mobile Number <span class="text-danger">*</span></label>
                                            <b-form-input id="communication_mobile_no" type="number"
                                                v-model="formData.communication_mobile_no"
                                                placeholder="Enter your Communication mobile number" />
                                            <b-form-invalid-feedback :state="communicationMobileState">
                                                <span v-if="errors.communication_mobile_no">{{
                                                    errors.communication_mobile_no[0]
                                                }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>


                                <div class="col-md-4">
                                    <b-form-group label-for="present_division_id">
                                        <template v-slot="label">
                                            <label>Division <span class="text-danger">*</span></label>
                                            <select class="form-control" id="present_division_id"
                                                v-model="formData.present_division_id"
                                                @change="getDistricts(formData.present_division_id)">
                                                <option value="null">Please Select Division</option>
                                                <option v-for="item in divisions" :value="item.id">{{ item.name }}</option>
                                            </select>
                                            <b-form-invalid-feedback :state="presentDivisionState">
                                                <span v-if="errors.present_division_id">{{ errors.present_division_id[0]
                                                }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="present_district_id">
                                        <template v-slot="label">
                                            <label>District <span class="text-danger">*</span></label>
                                            <select class="form-control" id="present_district_id"
                                                v-model="formData.present_district_id"
                                                @change="getthanas(formData.present_district_id)">
                                                <option value="null">Please Select District</option>
                                                <option v-for="item in districts" :value="item.id">{{ item.name }}</option>
                                            </select>
                                            <b-form-invalid-feedback :state="presentDistrictState">
                                                <span v-if="errors.present_district_id">{{ errors.present_district_id[0]
                                                }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="present_thana_id">
                                        <template v-slot="label">
                                            <label>thana <span class="text-danger">*</span></label>
                                            <select class="form-control" id="present_thana_id"
                                                v-model="formData.present_thana_id"
                                                @change="getPostCodes(formData.present_thana_id)">
                                                <option value="null">Please Select Thana</option>
                                                <option v-for="item in thanas" :value="item.id">{{ item.name }}</option>
                                            </select>
                                            <b-form-invalid-feedback :state="presentThanaState">
                                                <span v-if="errors.present_thana_id">{{ errors.present_thana_id[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>
                            </b-row>

                            <b-row>
                                <div class="col-md-4">
                                    <b-form-group label-for="present_postcode_id">
                                        <template v-slot="label">
                                            <label>Postcode <span class="text-danger">*</span></label>
                                            <select class="form-control" id="present_postcode_id"
                                                v-model="formData.present_postcode_id">
                                                <option value="null">Please Select Postcode</option>
                                                <option v-for="item in postCodes" :value="item.id">{{ item.name }}</option>
                                            </select>
                                            <b-form-invalid-feedback :state="presentPostcodeState">
                                                <span v-if="errors.present_postcode_id">{{ errors.present_postcode_id[0]
                                                }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="present_road_no">
                                        <template v-slot="label">
                                            <label>Present Road No <span class="text-danger">*</span></label>
                                            <b-form-input id="present_road_no" v-model="formData.present_road_no"
                                                placeholder="Enter present road number" />
                                            <b-form-invalid-feedback :state="presentRoadState">
                                                <span v-if="errors.present_road_no">{{ errors.present_road_no[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-4">
                                    <b-form-group label-for="present_house_no">
                                        <template v-slot="label">
                                            <label>Present House No <span class="text-danger">*</span></label>
                                            <b-form-input id="present_house_no" v-model="formData.present_house_no"
                                                placeholder="Enter present house number" />
                                            <b-form-invalid-feedback :state="presentHouseState">
                                                <span v-if="errors.present_house_no">{{ errors.present_house_no[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>
                            </b-row>

                            <b-button variant="info" class="mr-1" @click="submitForm('draft')" data-toggle="tooltip" data-placement="top" title="Click here to draft your information">Save Draft</b-button>
                            <b-button variant="primary" @click="submitForm('submit')" data-toggle="tooltip" data-placement="top" title="Click here to save your information">Next</b-button>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
  
<script>
import {
    BContainer, BCard, BCardBody, BCardGroup, BFormGroup, BFormInput, BButton, BRow,
    BFormDatepicker, BFormRadioGroup, BFormRadio, BFormSelect, BFormInvalidFeedback, BCol
} from 'bootstrap-vue'
import NavOnlineAdmission from '@/views/components/nav/NavOnlineAdmission.vue';
import SideNavOnlineAdmission from '@/views/components/nav/SideNavOnlineAdmission.vue';
import axios from 'axios';
import { mapState } from 'vuex';

export default {
    components: {
        BContainer,
        BCard,
        BCardBody,
        BCardGroup,
        BFormGroup,
        BFormInput,
        BButton,
        BRow,
        BFormDatepicker,
        BFormRadioGroup,
        BFormRadio,
        NavOnlineAdmission,
        BFormSelect,
        BFormInvalidFeedback,
        SideNavOnlineAdmission,
        BCol
    },
    data() {
        return {
            institute: this.$route.params.institute,
            formId: this.$route.params.formId,
            temporaryId: this.$route.params.temporaryId,
            groupState: false,
            emailState: false,
            classIdState: false,
            fatherNameState: false,
            fatherNidState: false,
            fatherMobileState: false,
            motherNameState: false,
            motherNidState: false,
            motherMobileState: false,
            guardianNameState: false,
            guardianNidState: false,
            guardianMobileState: false,
            religionState: false,
            nationalityState: false,
            communicationMobileState: false,
            presentDivisionState: false,
            presentDistrictState: false,
            presentThanaState: false,
            presentPostcodeState: false,
            presentRoadState: false,
            presentHouseState: false,
            classes: [],
            groups: [
                { value: 'null', text: 'Please Select Group' },
                { value: 'Science', text: 'Science' },
                { value: 'Arts', text: 'Arts' },
                { value: 'Commerce', text: 'Commerce' }
            ],
            religions: [
                { value: 'null', text: 'Please Select Religion' },
                { value: 'Islam', text: 'Islam' },
                { value: 'Hinduism', text: 'Hinduism' },
                { value: 'Christianity', text: 'Christianity' },
                { value: 'Buddhism', text: 'Buddhism' },
                { value: 'Sikhism', text: 'Sikhism' },
                { value: 'Other', text: 'Other' }
            ],

            nationalities: [
                { value: 'null', text: 'Please Select nationality' },
                { value: 'Bangladeshi', text: 'Bangladeshi' },
                { value: 'NRB', text: 'NRB' },
                { value: 'Foreign', text: 'Foreign' },
            ],
            divisions: [],
            districts: [],
            thanas: [],
            postCodes: [],

            formData: {
                group: '',
                class_id: '',
                email: '',
                father_name: '',
                father_nid: '',
                father_mobile_no: '',
                mother_name: '',
                mother_nid: '',
                mother_mobile_no: '',
                guardian_name: '',
                guardian_nid: '',
                guardian_mobile_no: '',
                religion: '',
                nationality: '',
                communication_mobile_no: '',
                present_division_id: '',
                present_district_id: '',
                present_thana_id: '',
                present_postcode_id: '',
                present_road_no: '',
                present_house_no: '',
                applicantId: '',
                institution_id: '',
            },
            errors: [],
        }
    },

    // computed: {
    //     formDataFromStore() {
    //         return this.$store.state.onlineAdmission.applicantDetailsData;
    //     },
    // },
    // created() {
    //     this.updateFormDataFromStore();
    // },


    created() {
        this.getapplicantDetailsdata();
        this.getSingleForm();
        this.getDivisions();
        if (this.$route.params.institute) {
            this.institute = this.$route.params.institute;
            localStorage.setItem('institute', this.institute); // Store in local storage
        }
        if (this.$route.params.formId) {
            this.formId = this.$route.params.formId;
            localStorage.setItem('formId', this.formId); // Store in local storage
        }
        if (this.$route.params.temporaryId) {
            this.temporaryId = this.$route.params.temporaryId;
            localStorage.setItem('temporaryId', this.temporaryId); // Store in local storage
        }
    },

    methods: {
        getDivisions() {
            axios.get(process.env.VUE_APP_API_URL + 'getDivision')
                .then(response => {
                    this.divisions = response.data.data;
                    this.formData.present_division_id = this.divisions[0].id;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getDistricts(divisionId) {
            axios.get(process.env.VUE_APP_API_URL + 'getDistrict/' + divisionId)
                .then(response => {
                    this.districts = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getthanas(districtId) {
            console.log(districtId);
            axios.get(process.env.VUE_APP_API_URL + 'getThana/' + districtId)
                .then(response => {
                    this.thanas = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getPostCodes(PostCodeId) {
            axios.get(process.env.VUE_APP_API_URL + 'getPostCode/' + PostCodeId)
                .then(response => {
                    this.postCodes = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        getSingleForm() {
            let institute = this.$route.params.institute;
            let formId = this.$route.params.formId;
            axios.get(process.env.VUE_APP_API_URL + `get-single-form/${institute}/${formId}`)
                .then(response => {
                    this.classes = response.data.data.class
                    let singleForm = response.data.data;
                    // this.$store.dispatch('onlineAdmission/singleForm', singleForm);
                    // console.log(response.data.data);
                })
                .catch(error => {
                    // console.log(error);
                });
        },

        getapplicantDetailsdata() {
            let temporaryId = this.$route.params.temporaryId;
            axios.get(process.env.VUE_APP_API_URL + 'get-online-admission-details', {
                params: { temporaryId }
            })
                .then(response => {
                    this.formData = response.data.data;
                    // console.log('POST request success:', response.data);
                    this.districts = response.data.data.districts;
                    this.thanas = response.data.data.AllThana;
                    this.postCodes = response.data.data.postCodes;
                })
                .catch(error => {
                    // console.log('POST request success:', error);
                });
        },

        submitForm(type) {
            let submitType = type;
            this.formData.submitType = submitType;
            this.formData.institute = this.$route.params.institute;
            axios.post(process.env.VUE_APP_API_URL + 'online-form-submit-details', this.formData)
                .then(response => {
                    console.log('POST request success:', response.data.data.submitType);
                    if (response.data.data.submitType == 'draft') {
                        this.$router.push({ name: 'admission', params: { institute: response.data.data.institute } });
                    } else if (response.data.data.submitType == 'submit') {
                        this.$router.push({ path: `/admission/${this.institute}/${this.formId}/temporaryId/${this.temporaryId}/attachment`, });
                        // this.$router.push({ path: `/admission/payment/${response.data.data.institute}/online-payment`, });
                    }

                })
                .catch(error => {
                    if (error.response.data.errors) {
                        this.errors = error.response.data.errors;
                        const fieldStateMap = {
                            group: 'groupState',
                            email: 'emailState',
                            class_id: 'classIdState',
                            father_name: 'fatherNameState',
                            father_nid: 'fatherNidState',
                            father_mobile_no: 'fatherMobileState',
                            mother_name: 'motherNameState',
                            mother_nid: 'motherNidState',
                            mother_mobile_no: 'motherMobileState',
                            guardian_name: 'guardianNameState',
                            guardian_nid: 'guardianNidState',
                            guardian_mobile_no: 'guardianMobileState',
                            religion: 'religionState',
                            nationality: 'nationalityState',
                            communication_mobile_no: 'communicationMobileState',
                            present_division_id: 'presentDivisionState',
                            present_district_id: 'presentDistrictState',
                            present_thana_id: 'presentThanaState',
                            present_postcode_id: 'presentPostcodeState',
                            present_road_no: 'presentRoadState',
                            present_house_no: 'presentHouseState',
                        };

                        for (const field in fieldStateMap) {
                            if (this.errors[field]) {
                                this[fieldStateMap[field]] = false;
                            }
                        }
                    }

                    console.error('POST request error:', error);
                });

        }
    },
    directives: {

    },
    props: {

    },
}
</script>
<style>
.error-message {
    margin-top: -.5rem;
    margin-bottom: 1rem;
}
</style>
  