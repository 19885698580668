<template>
    <nav class="navbar header-navbar navbar navbar-shadow align-items-center navbar-light navbar-expand">
        <b-container>
            <router-link :to="{ name: 'admission', params: { institute } }" class="navbar-brand">
                <img v-if="institute == 'apbn'" src="http://apbnpscbogra.edu.bd/wp-content/uploads/2018/11/Final-Logo.png" style="height: 35px;"
                    class="d-inline-block align-top" alt="">
                <img v-if="institute == 'ois'" src="https://ems.ois.edu.bd/img/ois-avatar.80050842.png" style="height: 35px;"
                    class="d-inline-block align-top" alt="">
                <span style="line-height: 25px; font-size: 25px; padding-left: 5px;" class="brand-text">{{
                    institute.toUpperCase() }}</span>
            </router-link>
        </b-container>
    </nav>
</template>
  
<script>
import {
    BContainer
} from 'bootstrap-vue'

export default {
    components: {
        BContainer,

    },
    data() {
        return {
            institute: this.$route.params.institute,
        }
    },
    props: {

    },
}
</script>
<style scoped></style>
  